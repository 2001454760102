import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React from 'react'
import { IcfIcdEntry } from '../../utils/IcfIcdType'

interface IcdInfoModalProps {
    open: boolean
    onDismiss: () => void
    icd: IcfIcdEntry | null
}

const IcfIcdInfoModal = ({
    open,
    onDismiss,
    icd,
}: IcdInfoModalProps): JSX.Element => {
    if (!icd) {
        return <></>
    }

    return (
        <IonModal isOpen={open} onDidDismiss={onDismiss} aria-modal>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Details</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onDismiss}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div>
                    <IonText>
                        <h3>{icd?.code}</h3>
                    </IonText>
                    <IonText>
                        <p>{icd?.label}</p>
                    </IonText>
                    {icd?.codingHint && (
                        <IonText>
                            <h4>Kodierungsempfehlung</h4>
                        </IonText>
                    )}
                    {icd?.codingHint && typeof icd?.codingHint === 'string' ? (
                        <IonText>
                            <p>{icd.codingHint}</p>
                        </IonText>
                    ) : (
                        typeof icd?.codingHint === 'object' &&
                        Array.isArray(icd?.codingHint?.paras) &&
                        icd?.codingHint?.paras?.map((para, index) => (
                            <IonText>
                                <p key={index}>
                                    {para.class === 'bold' ? (
                                        <b>{para.text}</b>
                                    ) : (
                                        para.text
                                    )}
                                </p>
                            </IonText>
                        ))
                    )}
                    {icd?.exclusion && (
                        <IonText>
                            <h4>Ausschluss</h4>
                        </IonText>
                    )}
                    {icd?.exclusion && (
                        <IonText>
                            <p>{icd.exclusion}</p>
                        </IonText>
                    )}

                    {icd?.inclusion && (
                        <IonText>
                            <h4>Einschluss</h4>
                        </IonText>
                    )}
                    {icd?.inclusion && (
                        <IonText>
                            <p>{icd.inclusion}</p>
                        </IonText>
                    )}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default IcfIcdInfoModal
