import React, { JSX, useCallback, useState } from 'react'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    SegmentValue,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import { useHistory } from 'react-router'
import {
    createInspection,
    selectInspectionById,
    updateInspection,
} from './InspectionSlice'
import { Form, Formik, FormikValues } from 'formik'
import { Inspection } from './InspectionType'
import InspectionForm from './InspectionForm'
import MedDrawer from './MedDrawer'

const InspectionEdit = (): JSX.Element => {
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const caseById = useAppSelector((state) =>
        selectCaseById(state, parseInt(caseId))
    )
    const inspectionId = caseById?.inspection
    const [segmentValue, setSegmentValue] =
        useState<SegmentValue>('documentation')
    const dispatch = useAppDispatch()
    const history = useHistory()
    const inspection = useAppSelector((state) =>
        selectInspectionById(state, parseInt(inspectionId))
    )
    const isNew = !Boolean(inspection)

    const handleSubmitUpdateForm = useCallback(
        async (values: FormikValues) => {
            const response = await dispatch(
                updateInspection({
                    id: inspectionId,
                    data: values,
                    caseId,
                    patientId,
                })
            ).unwrap()

            if (response.statusCode === 200) {
                await dispatch(fetchCaseById({ id: caseId, patientId }))
                history.push(`/patients/${patientId}/cases/${caseId}`)
            }
        },
        [caseId, dispatch, history, inspectionId, patientId]
    )

    const handleSubmitCreateForm = useCallback(
        async (values: FormikValues) => {
            const response = await dispatch(
                createInspection({
                    data: values,
                    caseId,
                    patientId,
                })
            ).unwrap()

            if (response.statusCode === 201) {
                await dispatch(fetchCaseById({ id: caseId, patientId }))
                history.push(`/patients/${patientId}/cases/${caseId}`)
            }
        },
        [caseId, dispatch, history, patientId]
    )

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {inspection
                            ? 'Inspektion bearbeiten'
                            : 'Neue Inspektion'}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    initialValues={(isNew ? {} : inspection) as Inspection}
                    onSubmit={
                        isNew ? handleSubmitCreateForm : handleSubmitUpdateForm
                    }
                >
                    <Form>
                        <IonSegment
                            value={segmentValue}
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                marginTop: '8px',
                                marginBottom: '8px',
                            }}
                            onIonChange={(e) =>
                                setSegmentValue(e.detail.value!)
                            }
                        >
                            <IonSegmentButton value="documentation">
                                Dokumentation
                            </IonSegmentButton>
                            <IonSegmentButton value="drawing">
                                Zeichenmodus
                            </IonSegmentButton>
                        </IonSegment>
                        {segmentValue === 'documentation' ? (
                            <InspectionForm inspectionId={inspection?.id} />
                        ) : (
                            <MedDrawer
                                width={inspection?.chartWidth}
                                height={inspection?.chartHeight}
                                storedLines={inspection?.lines}
                            />
                        )}
                    </Form>
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default InspectionEdit
