import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonPage,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import React, { JSX, useCallback, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    createTestEvaluation,
    deleteTestEvaluationById,
    fetchTestEvaluationById,
    selectTestEvaluationById,
    updateTestEvaluation,
} from './TestEvaluationSlice'
import moment from 'moment'
import 'moment/locale/de'
import { Formik, FormikValues } from 'formik'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'

import { selectTestSelectionByCase } from '../Case/selectors'
import IcfItem from './IcfItem'
import TestEvaluationTestEntry from './TestEvaluationTestEntry'

const TestEvaluationEdit = (): JSX.Element => {
    const match = useRouteMatch<{
        patientId: string
        caseId: string
        testEvaluationId: string
    }>()
    const dispatch = useAppDispatch()
    const { testEvaluationId, patientId, caseId } = match.params
    const isNew = testEvaluationId === 'new'

    useEffect(() => {
        if (!isNew)
            dispatch(
                fetchTestEvaluationById({
                    id: +testEvaluationId,
                    patientId,
                    caseId,
                })
            )
    }, [caseId, dispatch, isNew, patientId, testEvaluationId])

    const testEvaluation = useAppSelector((state) =>
        selectTestEvaluationById(state, parseInt(testEvaluationId))
    )
    const testSelectionByCase = useAppSelector((state) =>
        selectTestSelectionByCase(state, +caseId)
    )
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const history = useHistory()
    const [presentAlert] = useIonAlert()

    const onSubmit = useCallback(
        async (values: FormikValues) => {
            if (isNew) {
                await dispatch(
                    createTestEvaluation({ data: values, patientId, caseId })
                )
            } else {
                await dispatch(
                    updateTestEvaluation({
                        id: +testEvaluationId,
                        data: values,
                        patientId,
                        caseId,
                    })
                )
            }
            await dispatch(fetchCaseById({ id: caseId, patientId }))
            history.push(`/patients/${patientId}/cases/${caseId}`)
        },
        [caseId, dispatch, history, isNew, patientId, testEvaluationId]
    )

    const handleDelete = useCallback(async () => {
        await dispatch(
            deleteTestEvaluationById({
                id: testEvaluationId,
                patientId,
                caseId,
            })
        )
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [caseId, dispatch, history, patientId, testEvaluationId])

    const handleDeleteClick = useCallback(() => {
        presentAlert({
            header: `Wollen Sie die Testauswertung vom ${moment(
                testEvaluation?.date
            ).format('L')} wirklich löschen?`,
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                },
                {
                    text: 'Löschen',
                    role: 'delete',
                    handler: handleDelete,
                },
            ],
        })
    }, [handleDelete, presentAlert, testEvaluation?.date])

    if (!isNew && !testEvaluation) {
        return <></>
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {isNew
                            ? 'Neue Testauswertung'
                            : 'Testauswertung vom  ' +
                              moment(testEvaluation?.date).format('L')}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    initialValues={
                        isNew
                            ? {
                                  caseId,
                                  icfs: [],
                                  tests: [],
                                  rejectedTests: [],
                                  date: moment().format(),
                              }
                            : (testEvaluation as FormikValues)
                    }
                    onSubmit={onSubmit}
                >
                    {({ values, handleSubmit, handleChange, submitForm }) => (
                        <>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>ICF-Auswahl</IonLabel>
                                </IonListHeader>
                                {caseById?.caseIcfs ? (
                                    caseById.caseIcfs.map((icf: string) => (
                                        <IcfItem key={icf} icf={icf}></IcfItem>
                                    ))
                                ) : (
                                    <div className="ion-text-center ion-padding ion-margin">
                                        <IonText>
                                            Dem Fall sind keine ICFs zugeordnet
                                        </IonText>
                                    </div>
                                )}
                            </IonList>

                            <IonNote
                                color="medium"
                                class="ion-margin-horizontal"
                            >
                                Auf welche ICFs bezieht sich diese
                                Testauswertung (optional)?
                            </IonNote>

                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Test-Auswahl</IonLabel>
                                </IonListHeader>
                                {testSelectionByCase?.tests ? (
                                    testSelectionByCase.tests.map((testId) => (
                                        <IonItemGroup key={testId}>
                                            <TestEvaluationTestEntry
                                                testId={testId}
                                            />
                                        </IonItemGroup>
                                    ))
                                ) : (
                                    <div className="ion-text-center ion-padding ion-margin">
                                        <IonText>
                                            Dem Fall sind keine Tests zugeordnet
                                        </IonText>
                                    </div>
                                )}
                            </IonList>

                            <IonNote
                                color="medium"
                                class="ion-margin-horizontal"
                            >
                                Auf welche Tests bezieht sich diese
                                Testauswertung (optional)?
                            </IonNote>

                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Testauswertung</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonTextarea
                                        name="documentation"
                                        label="Dokumentation"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        value={values.documentation ?? ''}
                                    ></IonTextarea>
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        name="toDoNext"
                                        label="To Do Next"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        value={values.toDoNext ?? ''}
                                    ></IonTextarea>
                                </IonItem>
                            </IonList>

                            <IonList inset>
                                <IonItem
                                    button
                                    detail={false}
                                    onClick={submitForm}
                                >
                                    <IonLabel color="primary">
                                        Speichern
                                    </IonLabel>
                                </IonItem>
                            </IonList>

                            {!isNew && (
                                <IonList inset>
                                    <IonItem
                                        button
                                        detail={false}
                                        onClick={handleDeleteClick}
                                    >
                                        <IonLabel color="danger">
                                            Testauswertung löschen
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            )}
                        </>
                    )}
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default TestEvaluationEdit
