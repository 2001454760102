import React, { JSX } from 'react'
import {
    IonButton,
    IonChip,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectAimsByCase } from './selectors'
import { isEmpty } from 'lodash'
import AimTypeEnum from '../../utils/Enum/AimTypeEnum'

interface AimOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const AimTypeChip = ({ type }: { type: AimTypeEnum }): JSX.Element => {
    if (type === AimTypeEnum.TYPE_THERAPEUT) {
        return <IonChip color="secondary">Therapeutenziel</IonChip>
    } else {
        return <IonChip color="tertiary">Patientenziel</IonChip>
    }
}

const AimOverview = ({ caseId, disabled }: AimOverviewProps): JSX.Element => {
    const match = useRouteMatch()
    const aims = useAppSelector((state) => selectAimsByCase(state, +caseId))

    return (
        <IonList inset>
            <IonListHeader>
                <IonLabel>Ziele</IonLabel>
                <IonButton
                    routerLink={`${match.url}/aims/new`}
                    className="ion-hide-sm-down"
                    disabled={disabled}
                >
                    Ziel hinzufügen
                </IonButton>
                <IonButton
                    routerLink={`${match.url}/aims/new`}
                    className="ion-hide-sm-up"
                    disabled={disabled}
                >
                    <IonIcon slot="icon-only" icon={addOutline} />
                </IonButton>
            </IonListHeader>
            {!isEmpty(aims) ? (
                aims.map((aim) => (
                    <IonItem
                        key={aim.id}
                        button
                        routerLink={`${match.url}/aims/${aim.id}`}
                    >
                        <AimTypeChip type={aim.type} />
                        <IonLabel>
                            <IonText>{aim.description}</IonText>
                        </IonLabel>
                    </IonItem>
                ))
            ) : (
                <div className="ion-text-center ion-padding ion-margin">
                    <IonText>Keine Ziele angelegt</IonText>
                </div>
            )}
        </IonList>
    )
}

export default AimOverview
