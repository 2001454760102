import React, { JSX, useCallback } from 'react'
import {
    IonItem,
    IonItemDivider,
    IonLabel,
    IonNote,
    IonToggle,
    ToggleChangeEventDetail,
} from '@ionic/react'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectTestById } from '../Test/TestSlice'
import { intersection } from 'lodash'
import { useFormikContext } from 'formik'
import { TestEvaluation } from './TestEvaluationType'
import { IonToggleCustomEvent } from '@ionic/core/dist/types/components'

interface TestEvaluationTestEntryProps {
    testId: EntityId
}

const TestEvaluationTestEntry = ({
    testId,
}: TestEvaluationTestEntryProps): JSX.Element => {
    const selectedTest = useAppSelector((state) =>
        selectTestById(state, +testId)
    )
    const { values, setFieldValue } = useFormikContext<TestEvaluation>()

    const handleClick = useCallback(() => {
        if (values.tests?.includes(testId)) {
            const newTests = values.tests.filter((x) => x !== testId)
            setFieldValue('tests', newTests)
            const rejectedTests = values?.rejectedTests
                ? [...values.rejectedTests]
                : []

            setFieldValue(
                'rejectedTests',
                intersection(newTests, rejectedTests)
            )
        } else {
            const newTests = values.tests ? [...values.tests, testId] : [testId]
            setFieldValue('tests', newTests)
        }
    }, [setFieldValue, testId, values.rejectedTests, values.tests])

    const handleRejectedClick = useCallback(() => {
        const rejectedTests = values?.rejectedTests
            ? [...values.rejectedTests]
            : []

        if (rejectedTests.includes(testId)) {
            return
        } else {
            rejectedTests.push(testId)
            setFieldValue('rejectedTests', rejectedTests)
        }
    }, [setFieldValue, testId, values?.rejectedTests])

    const handleAcceptedClick = useCallback(() => {
        const rejectedTests = values?.rejectedTests
            ? [...values.rejectedTests]
            : []

        if (rejectedTests.includes(testId)) {
            rejectedTests.splice(rejectedTests.indexOf(testId), 1)
            setFieldValue('rejectedTests', rejectedTests)
        } else {
            return
        }
    }, [setFieldValue, testId, values.rejectedTests])

    const handleRejectionToggle = useCallback(
        (e: IonToggleCustomEvent<ToggleChangeEventDetail>) => {
            if (e.detail.checked) {
                handleRejectedClick()
            } else {
                handleAcceptedClick()
            }
        },
        [handleAcceptedClick, handleRejectedClick]
    )

    return (
        <>
            <IonItemDivider>
                <IonLabel>{selectedTest.name}</IonLabel>
            </IonItemDivider>
            <IonItem>
                <IonToggle
                    onIonChange={handleClick}
                    checked={values.tests?.includes(testId)}
                >
                    <IonLabel>Testauswertung</IonLabel>
                    <IonNote>
                        {`Aktivieren, wenn sich diese Testauswertung auf den Test ${selectedTest.name} bezieht`}
                    </IonNote>
                </IonToggle>
            </IonItem>
            {values.tests?.includes(testId) && (
                <IonItem>
                    <IonToggle
                        onIonChange={handleRejectionToggle}
                        checked={values.rejectedTests?.includes(testId)}
                    >
                        <IonLabel>Test verworfen</IonLabel>
                        <IonNote>
                            Aktivieren, wenn der Test im Laufe der Behandlung
                            verworfen wurde
                        </IonNote>
                    </IonToggle>
                </IonItem>
            )}
        </>
    )
}

export default TestEvaluationTestEntry
