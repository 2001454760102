import React, { JSX } from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectTestEvaluationsByCase } from '../Case/selectors'
import { isEmpty } from 'lodash'
import TestEvaluationItem from './TestEvaluationItem'

interface TestEvaluationOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const TestEvaluationOverview = ({
    caseId,
    disabled,
}: TestEvaluationOverviewProps): JSX.Element => {
    const match = useRouteMatch()
    const testEvluations = useAppSelector((state) =>
        selectTestEvaluationsByCase(state, +caseId)
    )

    return (
        <IonList inset>
            <IonListHeader>
                <IonLabel>Testauswertung</IonLabel>
                <>
                    <IonButton
                        routerLink={`${match.url}/testevaluations/new`}
                        className="ion-hide-sm-down"
                        disabled={disabled}
                    >
                        Testauswertung hinzufügen
                    </IonButton>
                    <IonButton
                        routerLink={`${match.url}/testevaluations/new`}
                        className="ion-hide-sm-up"
                        disabled={disabled}
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </>
            </IonListHeader>
            {!testEvluations || isEmpty(testEvluations) ? (
                <div className="ion-text-center ion-padding ion-margin">
                    <IonText>Keine Testauswertung angelegt</IonText>
                </div>
            ) : (
                testEvluations.map((testEvaluation) => (
                    <TestEvaluationItem
                        testEvaluationId={testEvaluation.id}
                        key={testEvaluation.id}
                    />
                ))
            )}
        </IonList>
    )
}

export default TestEvaluationOverview
