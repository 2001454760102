import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React, { JSX } from 'react'
import { Test } from '../Test/TestType'
import { isEmpty } from 'lodash'

interface TestDetailModalProps {
    open: boolean
    onDismiss: () => void
    test: Test
}

const TestDetailModal = ({
    open,
    onDismiss,
    test,
}: TestDetailModalProps): JSX.Element => {
    return (
        <IonModal isOpen={open} onDidDismiss={onDismiss} aria-modal>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Details</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onDismiss}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonText>
                    <h3>{test.name}</h3>
                </IonText>
                <IonText>
                    <p>
                        {!isEmpty(test.description)
                            ? test.description
                            : 'Keine Beschreibung vorhanden'}
                    </p>
                </IonText>
            </IonContent>
        </IonModal>
    )
}

export default TestDetailModal
