import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonHeader,
    IonIcon,
    IonList,
    IonListHeader,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    SearchbarCustomEvent,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchAllTests, selectAllTests } from '../Test/TestSlice'
import { selectTestsByIcf, selectTestSelectionByCase } from '../Case/selectors'
import { Virtuoso } from 'react-virtuoso'
import TestEntry from './TestEntry'
import { Form, Formik, FormikValues } from 'formik'
import { TestSelection } from './TestSelectionType'
import { isEmpty } from 'lodash'
import {
    createTestSelection,
    deleteTestSelectionById,
    updateTestSelection,
} from './TestSelectionSlice'
import { saveOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'

const TestSelectionTestSelection = (): JSX.Element => {
    const { patientId, caseId } = useParams<{
        patientId: string
        caseId: string
    }>()
    const allTests = useAppSelector((state) => selectAllTests(state))
    const recommendedTests = useAppSelector((state) =>
        selectTestsByIcf(state, +caseId)
    )
    const testSelection = useAppSelector((state) =>
        selectTestSelectionByCase(state, +caseId)
    )
    const [searchValue, setSearchValue] = useState<string | null>(null)
    const dispatch = useAppDispatch()
    const history = useHistory()

    const handleSearch = (e: SearchbarCustomEvent) => {
        setSearchValue(e.detail.value ?? null)
    }

    const tests = useMemo(() => {
        if (!searchValue) {
            return allTests
        }
        return allTests.filter((test) => {
            return test.name?.toLowerCase().includes(searchValue.toLowerCase())
        })
    }, [allTests, searchValue])

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            if (!testSelection) {
                await dispatch(
                    createTestSelection({
                        data: values,
                        patientId,
                        caseId,
                    })
                )
            } else if (!isEmpty(values.tests)) {
                await dispatch(
                    updateTestSelection({
                        data: values,
                        id: testSelection.id,
                        patientId,
                        caseId,
                    })
                )
            } else {
                await dispatch(
                    deleteTestSelectionById({
                        id: testSelection.id,
                        patientId,
                        caseId,
                    })
                )
            }
            history.push(`/patients/${patientId}/cases/${caseId}`)
        },
        [caseId, dispatch, history, patientId, testSelection]
    )

    useEffect(() => {
        dispatch(fetchAllTests())
    }, [dispatch])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>Testauswahl</IonTitle>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        autocapitalize="off"
                        debounce={1000}
                        onIonInput={handleSearch}
                    ></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    initialValues={
                        (testSelection
                            ? { ...testSelection }
                            : {
                                  tests: [],
                              }) as TestSelection
                    }
                    onSubmit={handleSubmit}
                    style={{ height: '100%' }}
                >
                    {({ values, setFieldValue, isSubmitting, submitForm }) => (
                        <Form style={{ height: '100%' }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <IonList
                                    style={{
                                        flex: 1,
                                        marginInlineStart: '16px',
                                        marginInlineEnd: '16px',
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Virtuoso
                                        style={{ height: '100%' }}
                                        totalCount={
                                            recommendedTests.length +
                                            tests.length
                                        }
                                        itemContent={(index) => {
                                            return (
                                                <>
                                                    {index <
                                                    recommendedTests.length ? (
                                                        <div
                                                            style={{
                                                                minHeight:
                                                                    '44px',
                                                            }}
                                                        >
                                                            {index === 0 && (
                                                                <IonListHeader>
                                                                    Empfohlen
                                                                </IonListHeader>
                                                            )}
                                                            <TestEntry
                                                                entry={
                                                                    recommendedTests[
                                                                        index
                                                                    ]
                                                                }
                                                                selected={values.tests.includes(
                                                                    recommendedTests[
                                                                        index
                                                                    ].id
                                                                )}
                                                                onCheckboxSelection={(
                                                                    id: number
                                                                ) => {
                                                                    let tests =
                                                                        [
                                                                            ...values.tests,
                                                                        ]

                                                                    const index =
                                                                        tests.indexOf(
                                                                            id
                                                                        )

                                                                    if (
                                                                        index >
                                                                        -1
                                                                    ) {
                                                                        tests.splice(
                                                                            index,
                                                                            1
                                                                        )
                                                                    } else {
                                                                        tests.push(
                                                                            id
                                                                        )
                                                                    }
                                                                    setFieldValue(
                                                                        'tests',
                                                                        tests,
                                                                        false
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                minHeight:
                                                                    '44px',
                                                            }}
                                                        >
                                                            {index ===
                                                                recommendedTests.length && (
                                                                <IonListHeader>
                                                                    Tests
                                                                </IonListHeader>
                                                            )}
                                                            <TestEntry
                                                                entry={
                                                                    tests[
                                                                        index -
                                                                            recommendedTests.length
                                                                    ]
                                                                }
                                                                selected={values.tests.includes(
                                                                    tests[
                                                                        index -
                                                                            recommendedTests.length
                                                                    ].id
                                                                )}
                                                                onCheckboxSelection={(
                                                                    id: number
                                                                ) => {
                                                                    let tests =
                                                                        [
                                                                            ...values.tests,
                                                                        ]

                                                                    const index =
                                                                        tests.indexOf(
                                                                            id
                                                                        )

                                                                    if (
                                                                        index >
                                                                        -1
                                                                    ) {
                                                                        tests.splice(
                                                                            index,
                                                                            1
                                                                        )
                                                                    } else {
                                                                        tests.push(
                                                                            id
                                                                        )
                                                                    }
                                                                    setFieldValue(
                                                                        'tests',
                                                                        tests,
                                                                        false
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        }}
                                    />
                                </IonList>
                            </div>
                            <IonFab
                                slot="fixed"
                                horizontal="center"
                                vertical="bottom"
                            >
                                <IonButton
                                    shape="round"
                                    className="ion-margin-bottom"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <IonIcon slot="start" icon={saveOutline} />
                                    Speichern
                                </IonButton>
                            </IonFab>
                        </Form>
                    )}
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default TestSelectionTestSelection
