import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import InspectionEdit from './InspectionEdit'

function InspectionIndex(): JSX.Element {
    const { path } = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route path={`${path}/`} component={InspectionEdit} />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default InspectionIndex
