import React, { JSX, useCallback, useEffect, useState } from 'react'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonHeader,
    IonIcon,
    IonList,
    IonListHeader,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    SearchbarCustomEvent,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { searchIcf } from '../../utils/IcfIcdHandler'
import { SearchResultIcf } from '../../utils/IcfIcdType'
import { Virtuoso } from 'react-virtuoso'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCaseById, updateCase } from '../Case/CaseSlice'
import IcfIcdSearchEntry from '../../components/IcfIcd/IcfIcdSearchEntry'
import { saveOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'

const IcfSelection = (): JSX.Element => {
    const { patientId, caseId } = useParams<{
        patientId: string
        caseId: string
    }>()
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const [caseIcfs, setCaseIcfs] = useState<string[]>(caseById?.caseIcfs ?? [])
    const [searchResult, setSearchResult] = useState<SearchResultIcf>({
        chapter: [],
        block: [],
        fourdigit: [],
        fivedigit: [],
        sixdigit: [],
        items: [],
        itemCount: 0,
        separationIndices: [],
    })
    const [submitting, setSubmitting] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const history = useHistory()

    const handleCheckboxSelection = useCallback(
        (checked: boolean, icf: string) => {
            let out = [...caseIcfs]
            const index = caseIcfs.indexOf(icf)

            if (index > -1) {
                out.splice(index, 1)
            } else {
                out.push(icf)
            }

            setCaseIcfs(out)
        },
        [caseIcfs]
    )

    const handleSearch = useCallback((e: SearchbarCustomEvent) => {
        let filter = searchIcf(e.detail.value ?? '')
        setSearchResult(filter)
    }, [])

    const handleSubmit = useCallback(async () => {
        setSubmitting(true)
        await dispatch(
            updateCase({
                data: { ...caseById, caseIcfs: caseIcfs },
                id: caseId,
                patientId,
            })
        )
        setSubmitting(false)
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [caseById, caseIcfs, caseId, dispatch, history, patientId])

    useEffect(() => {
        let filter = searchIcf('')
        setSearchResult(filter)
    }, [])

    useEffect(() => {
        setCaseIcfs(caseById?.caseIcfs ?? [])
    }, [caseById?.caseIcfs])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>ICFs</IonTitle>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        autocapitalize="off"
                        debounce={1000}
                        onIonInput={handleSearch}
                    ></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <IonList
                        style={{
                            flex: 1,
                            marginInlineStart: '16px',
                            marginInlineEnd: '16px',
                            marginTop: '16px',
                            marginBottom: '16px',
                            borderRadius: '10px',
                        }}
                    >
                        <Virtuoso
                            style={{ height: '100%' }}
                            totalCount={searchResult.itemCount}
                            itemContent={(index) => {
                                return (
                                    <div style={{ minHeight: '44px' }}>
                                        {index ===
                                            searchResult
                                                .separationIndices[0] && (
                                            <IonListHeader>
                                                Kapitel
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[1] && (
                                            <IonListHeader>
                                                Gruppe
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[2] && (
                                            <IonListHeader>
                                                Kategorie
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[3] && (
                                            <IonListHeader>
                                                Subkategorie 1
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[4] && (
                                            <IonListHeader>
                                                Subkategorie 2
                                            </IonListHeader>
                                        )}
                                        <IcfIcdSearchEntry
                                            entry={searchResult.items[index]}
                                            selected={caseIcfs.includes(
                                                searchResult.items[index].code
                                            )}
                                            onCheckboxSelection={
                                                handleCheckboxSelection
                                            }
                                        />
                                    </div>
                                )
                            }}
                        />
                    </IonList>
                </div>
                <IonFab slot="fixed" horizontal="center" vertical="bottom">
                    <IonButton
                        shape="round"
                        className="ion-margin-bottom"
                        onClick={handleSubmit}
                        disabled={submitting}
                    >
                        <IonIcon slot="start" icon={saveOutline} />
                        Speichern
                    </IonButton>
                </IonFab>
            </IonContent>
        </IonPage>
    )
}

export default IcfSelection
