import { IcfIcdEntry } from '../../utils/IcfIcdType'
import { IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { informationCircleOutline, trashOutline } from 'ionicons/icons'
import React, { JSX } from 'react'

interface IcfIcdItemProps {
    entry: IcfIcdEntry
    onInfo: (entry: IcfIcdEntry) => void
    onDelete: (entry: IcfIcdEntry) => void
}

const IcfIcdItem = ({
    entry,
    onInfo,
    onDelete,
}: IcfIcdItemProps): JSX.Element => (
    <IonItem>
        <IonLabel>
            <IonText>{`${entry.code} - ${entry.label}`}</IonText>
        </IonLabel>
        <IonButton
            id={'info-' + entry.code}
            onClick={() => onInfo(entry)}
            shape="round"
            fill="clear"
            slot="end"
        >
            <IonIcon slot="icon-only" icon={informationCircleOutline} />
        </IonButton>
        <IonButton
            onClick={() => onDelete(entry)}
            shape="round"
            fill="clear"
            slot="end"
        >
            <IonIcon color="danger" slot="icon-only" icon={trashOutline} />
        </IonButton>
    </IonItem>
)

export default IcfIcdItem
