import {
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonSelect,
    IonSelectOption,
    IonBackButton,
    IonItem,
    IonList,
    IonListHeader,
    IonLabel,
} from '@ionic/react'

import React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'
import { Formik, FormikProps, FormikValues } from 'formik'
import { logoutUser } from '../Auth/SessionSlice'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { fetchUserById, selectUserById, updateUser } from './UserSlice'
import { RootState } from '../../store'
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'
import { RouteComponentProps, withRouter } from 'react-router'

type UserDetailProps = {}

type UserDetailState = {
    dataLoading: boolean
}

type RouterProps = RouteComponentProps<{ id: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = UserDetailProps & PropsFromRedux & RouterProps

class UserDetail extends React.Component<Props, UserDetailState> {
    state = { dataLoading: true }

    componentDidMount() {
        const { fetchUserById, userId } = this.props

        if (!userId) {
            return
        }

        this.setState({ dataLoading: true })
        Promise.all([fetchUserById({ id: userId })])
            .then(() => {
                this.setState({ dataLoading: false })
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleSubmitForm = (values: FormikValues) => {
        const { updateUser, userId, history } = this.props

        if (!userId) {
            return
        }

        Promise.all([updateUser({ id: userId, data: values })])
            .then(() => {
                this.setState({ dataLoading: false })
                history.push('/users/')
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleLogout = () => {
        const { logoutUser, history } = this.props

        Promise.all([logoutUser()]).then(() => {
            history.push('/')
        })
    }

    handleRoleChange = (
        e: IonSelectCustomEvent<SelectChangeEventDetail>,
        props: FormikProps<FormikValues>
    ) => {
        const { setFieldValue } = props

        if (isEqual(e.detail.value, RoleEnum.ROLE_ADMIN)) {
            setFieldValue('role', RoleEnum.ROLE_ADMIN, false)
        } else if (isEqual(e.detail.value, RoleEnum.ROLE_MOD)) {
            setFieldValue('role', RoleEnum.ROLE_MOD, false)
        } else {
            setFieldValue('role', RoleEnum.ROLE_USER, false)
        }
    }

    renderRoleSelect = (props: FormikProps<FormikValues>) => {
        const { authenticatedUser } = this.props
        let value

        if (props.values.role === RoleEnum.ROLE_ADMIN) {
            value = RoleEnum.ROLE_ADMIN
        } else if (props.values.role === RoleEnum.ROLE_MOD) {
            value = RoleEnum.ROLE_MOD
        } else {
            value = RoleEnum.ROLE_USER
        }

        return (
            <IonSelect
                label="Rolle"
                interface="popover"
                onIonChange={(e) => this.handleRoleChange(e, props)}
                value={value}
                labelPlacement="fixed"
            >
                {authenticatedUser?.role === RoleEnum.ROLE_ADMIN && (
                    <IonSelectOption value={RoleEnum.ROLE_ADMIN}>
                        Admin
                    </IonSelectOption>
                )}
                <IonSelectOption value={RoleEnum.ROLE_MOD}>
                    Moderator
                </IonSelectOption>
                <IonSelectOption value={RoleEnum.ROLE_USER}>
                    Student
                </IonSelectOption>
            </IonSelect>
        )
    }

    renderFieldset2 = (props: FormikProps<FormikValues>) => {
        const { userId, authenticatedUser } = this.props
        const { handleChange, values, handleSubmit } = props

        return (
            <>
                <IonList inset>
                    <IonListHeader>
                        <IonLabel>Persönliche Angaben</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonInput
                            label="Vorname"
                            name="firstName"
                            labelPlacement="fixed"
                            value={values.firstName ? values.firstName : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput
                            label="Nachname"
                            name="lastName"
                            labelPlacement="fixed"
                            value={values.lastName ? values.lastName : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput
                            label="E-Mail"
                            name="email"
                            labelPlacement="fixed"
                            value={values.email ? values.email : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonItem>
                </IonList>
                {authenticatedUser &&
                    [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_MOD].includes(
                        authenticatedUser.role
                    ) && (
                        <IonList inset>
                            <IonListHeader>
                                <IonLabel>Berechtigungen</IonLabel>
                            </IonListHeader>
                            <IonItem>{this.renderRoleSelect(props)}</IonItem>
                        </IonList>
                    )}
                <IonList inset>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => handleSubmit()}
                    >
                        <IonLabel color="primary">Speichern</IonLabel>
                    </IonItem>
                </IonList>
                {isEqual(userId, authenticatedUser?.id) && (
                    <IonList inset>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => this.handleLogout()}
                        >
                            <IonLabel color="danger">Abmelden</IonLabel>
                        </IonItem>
                    </IonList>
                )}
            </>
        )
    }

    render() {
        const { user } = this.props
        const { dataLoading } = this.state
        let initialValues = {}

        if (dataLoading || isEmpty(user) || !user.role) {
            return null
        }

        initialValues = {
            ...user,
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/dashboard/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            Benutzer: {user.firstName} {user.lastName}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color="light">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                {this.renderFieldset2(props)}
                            </form>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const {
        session: { user: authenticatedUser },
    } = state
    const id = parseInt(ownProps.match?.params?.id)

    return {
        user: selectUserById(state, id),
        userId: id ? id : null,
        //courses: selectAllCourses(state),
        authenticatedUser: authenticatedUser,
    }
}

const mapDispatch = {
    fetchUserById,
    //fetchAllCourses,
    updateUser,
    logoutUser,
}

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(UserDetail))
