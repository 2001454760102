import { Layer, Line, Stage, Image } from 'react-konva'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import bodychart from '../../assets/bodychart.png'
import { useFormikContext } from 'formik'
import { Inspection, Lines } from './InspectionType'
import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react'

interface MedDrawerPageProps {
    width?: number
    height?: number
    storedLines?: Lines
}

function MedDrawerPage({
    width = 852,
    height = 699,
    storedLines,
}: MedDrawerPageProps) {
    const { setFieldValue, submitForm, values } = useFormikContext<Inspection>()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tool, setTool] = useState('pen')
    const [lines, setLines] = useState<Lines>(storedLines ?? [])
    const [image, setImage] = useState<HTMLImageElement>()
    const isDrawing = useRef(false)

    const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        isDrawing.current = true
        const pos = e.target.getStage()?.getPointerPosition()
        if (pos) {
            setLines([...lines, { tool, points: [pos.x, pos.y] }])
        }
    }

    const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return
        }
        const stage = e.target.getStage()
        if (!stage) {
            return
        }
        const point = stage.getPointerPosition()
        if (!point) {
            return
        }
        let lastLine = lines[lines.length - 1]
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y])

        // replace last
        lines.splice(lines.length - 1, 1, lastLine)
        setLines(lines.concat())
    }

    const handleMouseUp = () => {
        isDrawing.current = false
        setFieldValue('lines', lines)
    }

    const handleTouchStart = useCallback(
        (e: KonvaEventObject<TouchEvent>) => {
            isDrawing.current = true
            const pos = e.target.getStage()?.getPointerPosition()
            if (pos) {
                setLines([...lines, { tool, points: [pos.x, pos.y] }])
            }
        },
        [lines, tool]
    )

    const handleTouchMove = useCallback(
        (e: KonvaEventObject<TouchEvent>) => {
            // no drawing - skipping
            if (!isDrawing.current) {
                return
            }
            const stage = e.target.getStage()
            if (!stage) {
                return
            }
            const point = stage.getPointerPosition()
            if (!point) {
                return
            }
            let lastLine = lines[lines.length - 1]
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y])

            // replace last
            lines.splice(lines.length - 1, 1, lastLine)
            setLines(lines.concat())
        },
        [lines]
    )

    const handleTouchEnd = useCallback(() => {
        isDrawing.current = false
        setFieldValue('lines', lines)
    }, [lines, setFieldValue])

    const resetLines = useCallback(() => {
        setLines([])
        setFieldValue('lines', [])
    }, [setFieldValue])

    useEffect(() => {
        const tempimage = new window.Image()
        tempimage.src = bodychart
        setImage(tempimage)
    }, [])

    const [computedWidth, computedHeight] = useMemo(() => {
        const imgWidth = 852
        const imgHeight = 699
        const ar = imgHeight / imgWidth

        let computedHeight = imgHeight,
            computedWidth = imgWidth

        //if (window.innerWidth < computedWidth * 0.75) {
        computedWidth = window.innerWidth * 0.85
        computedHeight = ar * computedWidth
        //}

        return [computedWidth, computedHeight]

        /* const imgWidth = 852
        const imgHeight = 699
        const ar = imgHeight / imgWidth
        //const pm = 0.75
        const innerWidth = window.innerWidth
        const innerHeight = window.innerHeight
        let height, width

        if (innerWidth >= imgWidth && innerHeight >= imgHeight) {
            height = imgHeight
            width = imgWidth
        } else if (innerWidth < imgWidth && innerHeight > imgHeight) {
            width = innerWidth
            height = imgHeight * (innerWidth / imgWidth)
        } else if (innerWidth > imgWidth && innerHeight < imgHeight) {
            height = innerHeight
            width = imgWidth * (innerHeight / imgHeight)
        } else {
            if (innerHeight / innerWidth <= ar) {
                height = innerHeight
                width = innerHeight * ar
            } else {
                width = innerWidth
                height = innerWidth * ar
            }
        }*/
    }, [])

    /*    if (window.innerWidth * ar > 0.75 * window.innerHeight) {
        height = 0.75 * window.innerHeight
        width = (height * 1) / ar
    } else {
        width = window.innerWidth
        height = width * ar
    }*/

    useEffect(() => {
        if (
            computedWidth !== values.chartWidth ||
            computedHeight !== values.chartHeight
        ) {
            setFieldValue('chartWidth', computedWidth)
            setFieldValue('chartHeight', computedHeight)
            const newLines = lines.map((line) => ({
                tool: line.tool,
                points: line.points.map(
                    (point) => point / (width / computedWidth)
                ),
            }))
            setLines(newLines)
        }
    }, [
        computedWidth,
        computedHeight,
        setFieldValue,
        width,
        height,
        lines,
        values.chartWidth,
        values.chartHeight,
    ])

    return (
        <>
            <IonToolbar style={{ '--background': 'transparent' }}>
                <IonButtons slot="start">
                    <IonButton onClick={resetLines} color="primary">
                        Zeichnung zurücksetzen
                    </IonButton>
                </IonButtons>
                <IonTitle>Zeichenmodus</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={submitForm} color="primary">
                        Speichern
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <div
                id="med-drawer-container"
                style={{
                    padding: '8px',
                    width: computedWidth,
                    height: computedHeight,
                }}
            >
                <Stage
                    width={computedWidth}
                    height={computedHeight}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <Layer>
                        <Image
                            x={0}
                            y={0}
                            width={computedWidth}
                            height={computedHeight}
                            image={image}
                        />
                        {/*<Text text="Just start drawing" x={5} y={30} />*/}
                        {lines.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke="#df4b26"
                                strokeWidth={2}
                                tension={0.5}
                                lineCap="round"
                                lineJoin="round"
                                globalCompositeOperation={
                                    line.tool === 'eraser'
                                        ? 'destination-out'
                                        : 'source-over'
                                }
                            />
                        ))}
                    </Layer>
                </Stage>

                {/*<select
                value={tool}
                onChange={(e) => {
                    setTool(e.target.value)
                }}
            >
                <option value="pen">Pen</option>
                <option value="eraser">Eraser</option>
            </select>*/}
            </div>
        </>
    )
}

export default MedDrawerPage
