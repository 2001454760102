import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { isEmpty } from 'lodash'
import {
    addOutline,
    femaleOutline,
    maleFemaleOutline,
    maleOutline,
} from 'ionicons/icons'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import {
    deletePatientById,
    fetchAllPatients,
    selectAllPatients,
} from './PatientSlice'
import { RootState } from '../../store'
import { Patient } from './PatientType'
import GenderEnum from '../../utils/Enum/GenderEnum'

type PatientListProps = {}

type PatientListState = {
    dataLoading: boolean
    deleteDialogOpen: boolean
    optionToDelete?: number
}

type RouterProps = RouteComponentProps

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientListProps & PropsFromRedux & RouterProps

class PatientList extends React.Component<Props, PatientListState> {
    state: PatientListState = { dataLoading: false, deleteDialogOpen: false }

    componentDidMount() {
        const { fetchAllPatients } = this.props

        this.setState({ dataLoading: true })
        Promise.all([fetchAllPatients()])
            .then(() => {
                this.setState({ dataLoading: false })
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleDelete = (
        e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
        id: number
    ) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeletePatient = async (id: number) => {
        const { deletePatientById } = this.props

        await deletePatientById({ id })
        this.setState({ deleteDialogOpen: false, optionToDelete: undefined })
    }

    renderListItem = (patient: Patient, index: number) => {
        return (
            <IonItem key={index} routerLink={`/patients/${patient.id}`}>
                <IonIcon
                    aria-hidden="true"
                    icon={
                        patient.gender === GenderEnum.GENDER_MALE
                            ? maleOutline
                            : patient.gender === GenderEnum.GENDER_FEMALE
                              ? femaleOutline
                              : maleFemaleOutline
                    }
                    slot="start"
                ></IonIcon>
                <IonLabel>
                    <IonText>
                        {patient.lastName}, {patient.firstName}
                    </IonText>
                    <p>
                        <IonText>
                            Gebursdatum:{' '}
                            {new Date(patient.birthday).toLocaleString(
                                'de-DE',
                                {
                                    timeZone: 'Europe/Berlin',
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                } as Intl.DateTimeFormatOptions
                            )}
                        </IonText>
                    </p>
                </IonLabel>
            </IonItem>
        )
    }

    renderDeleteDialog = () => {
        const { patients } = this.props
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete || !patients[optionToDelete]) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={
                        'Patient  ' +
                        patients[optionToDelete].firstName +
                        ' ' +
                        patients[optionToDelete].lastName +
                        ' wirklich löschen?'
                    }
                    onDelete={() => {
                        this.handleDeletePatient(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderList = () => {
        const { patients } = this.props

        return (
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Patienten</IonLabel>
                    <IonButton
                        routerLink="/patients/new"
                        className="ion-hide-sm-down"
                    >
                        Patient anlegen
                    </IonButton>
                    <IonButton
                        routerLink="/patients/new"
                        className="ion-hide-sm-up"
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {patients.map(this.renderListItem)}
            </IonList>
        )
    }

    renderEmptyPatients = () => {
        return (
            <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <p>Keine Patienten vorhanden</p>
                </IonRow>

                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <IonButton routerLink="/patients/new" shape="round">
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonRow>
            </IonGrid>
        )
    }

    render() {
        const { patients } = this.props
        const { dataLoading } = this.state

        if (dataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/dashboard/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Patientenübersicht</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen color="light">
                    {isEmpty(patients)
                        ? this.renderEmptyPatients()
                        : this.renderList()}
                    {this.renderDeleteDialog()}
                </IonContent>
            </IonPage>
        )
    }
}
const mapState = (state: RootState) => {
    return {
        patients: selectAllPatients(state),
    }
}

const mapDispatch = { fetchAllPatients, deletePatientById }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(PatientList))
