import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCard,
    IonCardContent,
    IonIcon,
    IonItem,
    IonText,
    IonBackButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
} from '@ionic/react'
import { alertCircleOutline, informationCircleOutline } from 'ionicons/icons'
import './case.css'
import {
    makeFlagsByCase,
    makeDarkRedFlagsByCase,
    makeTestsByTestSelection,
} from './selectors'
import { isEqual } from 'lodash'
import Documentation from './components/Documentation'
import { deleteCaseById, fetchCaseById, selectCaseById } from './CaseSlice'
import { RootState } from '../../store'
import FlagsOverview from '../Flag/FlagsOverview'
import AnamnesticCaseAnalysisOverview from '../AnamnesticCaseAnalysis/AnamnesticCaseAnalysisOverview'
import IcdOverview from '../ICD/IcdOverview'
import AimOverview from '../Aim/AimOverview'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import IcfOverview from '../ICF/IcfOverview'
import InspectionOverview from '../Inspection/InspectionOverview'
import TestSelectionOverview from '../TestSelection/TestSelectionOverview'
import TestEvaluationOverview from '../TestEvaluation/TestEvaluationOverview'

type CaseOverviewProps = {}

type CaseOverviewState = {}

type RouterProps = RouteComponentProps<{
    caseId: string
    patientId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = CaseOverviewProps & RouterProps & PropsFromRedux

class CaseOverview extends React.Component<Props, CaseOverviewState> {
    state = {
        dataLoading: true,
        segmentValue: 'option-1',
        deleteDialogOpen: false,
    }

    componentDidMount() {
        const { isNewCase, fetchCaseById, caseId, patientId } = this.props

        if (!isNewCase) {
            const promises = [fetchCaseById({ id: caseId, patientId })]

            this.setState({ dataLoading: true })
            Promise.all(promises)
                .then(() => {
                    this.setState({ dataLoading: false })
                })
                .catch(() => {
                    this.setState({ dataLoading: false })
                })
        }
        this.setState({ dataLoading: false })
    }

    handleDeleteDialogOpen = () => {
        this.setState({ deleteDialogOpen: true })
    }

    handleDismissDeleteDialog = () => {
        this.setState({ deleteDialogOpen: false })
    }

    handleDeleteCase = async () => {
        const { deleteCaseById, caseId, patientId, history } = this.props
        await deleteCaseById({ id: caseId, patientId })
        history.push(`/patients/${patientId}`)
    }

    renderInformationArea = () => {
        const { isNewCase } = this.props

        if (!isNewCase) {
            return null
        }

        return (
            <IonGrid>
                <IonRow>
                    <IonCard
                        className="full-width-card"
                        style={{
                            borderLeft: '6px solid #3880ff',
                            boxShadow: 'unset',
                            background: '#ebf2ff',
                        }}
                    >
                        <IonCardContent>
                            <IonItem
                                lines="none"
                                style={{ '--background': 'transparent' }}
                            >
                                <IonIcon
                                    color="primary"
                                    size="large"
                                    slot="start"
                                    icon={informationCircleOutline}
                                />
                                <IonText color="primary">
                                    Zum Anlegen eines Falls anamnestische
                                    Fallanalyse angeben oder eine Flagge
                                    auswählen
                                </IonText>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                </IonRow>
            </IonGrid>
        )
    }

    renderDarkRedFlagArea = () => {
        const { hasDarkRedFlags } = this.props

        if (!hasDarkRedFlags) {
            return null
        }

        return (
            <IonGrid>
                <IonRow>
                    <IonCard
                        className="full-width-card"
                        style={{
                            borderLeft: '6px solid #eb445a',
                            boxShadow: 'unset',
                            background: '#fdecef',
                        }}
                    >
                        <IonCardContent>
                            <IonItem
                                lines="none"
                                style={{ '--background': 'transparent' }}
                            >
                                <IonIcon
                                    color="danger"
                                    size="large"
                                    slot="start"
                                    icon={alertCircleOutline}
                                />
                                <IonText color="danger">
                                    Es wurde eine dunkelrote Flagge hinterlegt.
                                </IonText>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                </IonRow>
            </IonGrid>
        )
    }

    renderDocumentationArea = () => {
        const { isNewCase, hasDarkRedFlags } = this.props
        const { dataLoading } = this.state

        return (
            <Documentation
                disabled={hasDarkRedFlags || isNewCase}
                dataLoading={dataLoading}
            />
        )
    }

    render() {
        const { thisCase, isNewCase, match, caseId, hasDarkRedFlags } =
            this.props
        const { dataLoading, segmentValue, deleteDialogOpen } = this.state

        if (dataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/${match.params.patientId}`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            Fallübersicht für Fall:{' '}
                            {isNewCase ? 'neuer Fall' : thisCase?.id}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color="light">
                    {this.renderDarkRedFlagArea()}
                    {this.renderInformationArea()}
                    <IonSegment
                        style={{
                            width: '90%',
                            margin: '0 auto',
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}
                        value={segmentValue}
                        onIonChange={(e) => {
                            this.setState({ segmentValue: e.detail.value })
                        }}
                    >
                        <IonSegmentButton value={'option-1'}>
                            <IonLabel>Anamnese</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value={'option-2'}>
                            <IonLabel>Untersuchung</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value={'option-3'}>
                            <IonLabel>Behandlung</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>

                    {segmentValue === 'option-1' && (
                        <>
                            <AnamnesticCaseAnalysisOverview caseId={caseId} />
                            <FlagsOverview caseId={caseId} />
                            <IcdOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                            <AimOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                        </>
                    )}
                    {segmentValue === 'option-2' && (
                        <>
                            <IcfOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                            <InspectionOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                            <TestSelectionOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                            <TestEvaluationOverview
                                caseId={caseId}
                                disabled={hasDarkRedFlags || isNewCase}
                            />
                        </>
                    )}
                    {segmentValue === 'option-3' && (
                        <IonGrid>
                            <IonRow>{this.renderDocumentationArea()}</IonRow>
                        </IonGrid>
                    )}
                    {!isNewCase && (
                        <IonList inset>
                            <IonItem
                                button
                                detail={false}
                                onClick={this.handleDeleteDialogOpen}
                            >
                                <IonLabel color="danger">Fall löschen</IonLabel>
                            </IonItem>
                        </IonList>
                    )}
                </IonContent>
                <DeleteDialog
                    header="Möchten Sie das den Fall wirklich löschen?"
                    onDelete={this.handleDeleteCase}
                    onDidDismiss={this.handleDismissDeleteDialog}
                    isOpen={deleteDialogOpen}
                />
            </IonPage>
        )
    }
}

const makeMapState = () => {
    const getFlagsByCase = makeFlagsByCase()
    const getDarkRedFlags = makeDarkRedFlagsByCase()
    const getTestsByTestSelection = makeTestsByTestSelection()

    return (state: RootState, ownProps: RouterProps) => {
        const match = ownProps.match
        const caseId = parseInt(match.params.caseId)
        const flagsByCase = getFlagsByCase(state, caseId)
        const darkRedFlags = getDarkRedFlags(state, caseId)
        const testsByTestSelection = getTestsByTestSelection(state, caseId)

        const isNewCase = isEqual(match.params.caseId, 'new')

        return {
            match: match,
            patientId: match.params.patientId,
            isNewCase: isNewCase,
            caseId: match.params.caseId,
            thisCase: selectCaseById(state, caseId),
            flags: flagsByCase,
            darkRedFlags: darkRedFlags,
            hasDarkRedFlags: !!(darkRedFlags && darkRedFlags.length > 0),
            testsByTestSelection: testsByTestSelection,
        }
    }
}

const mapDispatch = { fetchCaseById, deleteCaseById }

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(CaseOverview))
