import React, { JSX, useCallback } from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { EntityId } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { addOutline } from 'ionicons/icons'
import { isEmpty, without } from 'lodash'
import { selectIcdsByCase } from '../Case/selectors'
import { IcfIcdEntry } from '../../utils/IcfIcdType'
import IcfIcdInfoModal from '../../components/IcfIcd/IcfIcdInfoModal'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import { selectCaseById, updateCase } from '../Case/CaseSlice'
import { useParams, useRouteMatch } from 'react-router-dom'
import IcfIcdItem from '../../components/IcfIcd/IcfIcdItem'

interface IcdOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const IcdOverview = ({ caseId, disabled }: IcdOverviewProps): JSX.Element => {
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const icds = useAppSelector((state) => selectIcdsByCase(state, +caseId))
    const [infoModalOpen, setInfoModalOpen] = React.useState(false)
    const [selectedIcd, setSelectedIcd] = React.useState<IcfIcdEntry | null>(
        null
    )
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const { patientId } = useParams<{ patientId: string }>()
    const { url } = useRouteMatch()

    const handleDelete = async () => {
        const values = {
            ...caseById,
            caseIcds: without(caseById?.caseIcds, selectedIcd!.code),
        }
        await dispatch(updateCase({ data: values, id: caseId, patientId }))

        setSelectedIcd(null)
        setDeleteDialogOpen(false)
    }

    const handleInfoOpen = useCallback((icd: IcfIcdEntry) => {
        setSelectedIcd(icd)
        setInfoModalOpen(true)
    }, [])

    const handleDeleteDialogOpen = useCallback((icd: IcfIcdEntry) => {
        setSelectedIcd(icd)
        setDeleteDialogOpen(true)
    }, [])

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>ICD</IonLabel>
                    <IonButton
                        routerLink={`${url}/icd`}
                        className="ion-hide-sm-down"
                        disabled={disabled}
                    >
                        ICD hinzufügen
                    </IonButton>
                    <IonButton
                        routerLink={`${url}/icd`}
                        className="ion-hide-sm-up"
                        disabled={disabled}
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {icds['category'] && !isEmpty(icds['category']) ? (
                    <>
                        <IonListHeader>
                            <IonText>Kapitel</IonText>
                        </IonListHeader>
                        {icds['category'].map((icd: IcfIcdEntry) => (
                            <IcfIcdItem
                                entry={icd}
                                key={icd.code}
                                onDelete={handleDeleteDialogOpen}
                                onInfo={handleInfoOpen}
                            />
                        ))}
                    </>
                ) : (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>Keine ICDs ausgewählt</IonText>
                    </div>
                )}
            </IonList>
            <IcfIcdInfoModal
                open={infoModalOpen}
                icd={selectedIcd}
                onDismiss={() => {
                    setInfoModalOpen(false)
                }}
            />
            <DeleteDialog
                header={`${selectedIcd?.code} wirklich löschen?`}
                onDelete={handleDelete}
                onDidDismiss={() => {
                    setDeleteDialogOpen(false)
                }}
                isOpen={deleteDialogOpen}
            />
        </>
    )
}

export default IcdOverview
