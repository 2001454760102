import React, { JSX } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectTestById } from '../Test/TestSlice'
import { Test } from '../Test/TestType'
import { informationCircleOutline, trashOutline } from 'ionicons/icons'

interface TestSelectionOverviewEntryProps {
    testId: EntityId
    onInfo: (test: Test) => void
    onDelete: (test: Test) => void
}

const TestSelectionOverviewEntry = ({
    testId,
    onInfo,
    onDelete,
}: TestSelectionOverviewEntryProps): JSX.Element => {
    const testById = useAppSelector((state) => selectTestById(state, +testId))

    return (
        <IonItem>
            <IonLabel>
                <IonText>{testById.name}</IonText>
            </IonLabel>
            <IonButton
                id={'info-' + testById.id}
                onClick={() => onInfo(testById)}
                shape="round"
                fill="clear"
                slot="end"
            >
                <IonIcon slot="icon-only" icon={informationCircleOutline} />
            </IonButton>
            <IonButton
                onClick={() => onDelete(testById)}
                shape="round"
                fill="clear"
                slot="end"
            >
                <IonIcon color="danger" slot="icon-only" icon={trashOutline} />
            </IonButton>
        </IonItem>
    )
}

export default TestSelectionOverviewEntry
