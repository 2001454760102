import React, { JSX, useCallback } from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonTextarea,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Inspection } from './InspectionType'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import { deleteInspectionById } from './InspectionSlice'
import { EntityId } from '@reduxjs/toolkit'
import { useHistory } from 'react-router'
import { useAppDispatch } from '../../hooks'

interface InspectionFormProps {
    inspectionId?: EntityId
}

const InspectionForm = ({ inspectionId }: InspectionFormProps): JSX.Element => {
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const { values, handleChange, submitForm } = useFormikContext<Inspection>()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDelete = useCallback(async () => {
        await dispatch(
            deleteInspectionById({ id: inspectionId!, patientId, caseId })
        )
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [inspectionId, caseId, dispatch, history, patientId])

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Inspektion</IonLabel>
                </IonListHeader>
                <IonItem>
                    <IonTextarea
                        name="inspection"
                        label="Inspektion"
                        labelPlacement="floating"
                        autoGrow={true}
                        onIonChange={handleChange}
                        value={values.inspection ?? ''}
                    ></IonTextarea>
                </IonItem>
                <IonItem>
                    <IonTextarea
                        name="inspectionHint"
                        label="Relevante Hinweise aus der Inspektion und die Planung der weiteren Untersuchung"
                        labelPlacement="floating"
                        autoGrow={true}
                        onIonChange={handleChange}
                        value={values.inspectionHint ?? ''}
                    ></IonTextarea>
                </IonItem>
            </IonList>
            <IonList inset>
                <IonItem
                    button
                    detail={false}
                    routerLink={`/patients/${patientId}/cases/${caseId}`}
                    onClick={submitForm}
                >
                    <IonLabel color="primary">Speichern</IonLabel>
                </IonItem>
            </IonList>
            <IonList inset>
                <IonItem button detail={false} onClick={handleOpen}>
                    <IonLabel color="danger">Inspektion löschen</IonLabel>
                </IonItem>
            </IonList>
            <DeleteDialog
                header="Möchten Sie die Inspektion wirklich löschen?"
                onDelete={handleDelete}
                onDidDismiss={handleClose}
                isOpen={open}
            />
        </>
    )
}

export default InspectionForm
