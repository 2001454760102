import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonText,
} from '@ionic/react'
import { useAppSelector } from '../../hooks'
import { getCasesByPatient } from './selectors'
import { EntityId } from '@reduxjs/toolkit'
import { useRouteMatch } from 'react-router-dom'
import { addOutline } from 'ionicons/icons'
import React, { JSX } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment/moment'

interface PatientCaseOverviewProps {
    patientId: EntityId
}

const PatientCaseOverview = ({
    patientId,
}: PatientCaseOverviewProps): JSX.Element => {
    const casesByPatient = useAppSelector((state) =>
        getCasesByPatient(state, +patientId)
    )
    const match = useRouteMatch()

    const CasesList = () => {
        return (
            <>
                {casesByPatient?.map((caseData) => (
                    <IonItem
                        routerLink={`${match.url}/cases/${caseData.id}`}
                        key={caseData.id}
                    >
                        <IonLabel>
                            <strong>
                                {`Behandlung vom ${moment(caseData.reportDate).format('L')}`}
                            </strong>
                            <br />
                            <IonText>
                                Letzte Behandlung:{' '}
                                {moment(caseData.lastMedication).format('L')}
                            </IonText>
                            {!isEmpty(caseData.caseIcfs) && (
                                <>
                                    <br />
                                    <IonNote className="ion-text-wrap">
                                        ICFs: {caseData.caseIcfs?.join(', ')}
                                    </IonNote>
                                </>
                            )}
                        </IonLabel>
                    </IonItem>
                ))}
            </>
        )
    }

    return (
        <IonList inset>
            <IonListHeader>
                <IonLabel>Fallübersicht</IonLabel>
                <IonButton
                    routerLink={`${match.url}/cases/new`}
                    className="ion-hide-sm-down"
                >
                    Fall anlegen
                </IonButton>
                <IonButton
                    routerLink={`${match.url}/cases/new`}
                    className="ion-hide-sm-up"
                >
                    <IonIcon slot="icon-only" icon={addOutline} />
                </IonButton>
            </IonListHeader>
            {isEmpty(casesByPatient) ? (
                <div className="ion-text-center ion-padding ion-margin">
                    <IonText>Keine Fälle vorhanden</IonText>
                </div>
            ) : (
                <CasesList />
            )}
        </IonList>
    )
}

export default PatientCaseOverview
