import { createSelector } from '@reduxjs/toolkit'
import { selectCaseById } from './CaseSlice'
import { selectAllFlags } from '../Flag/FlagSlice'
import { RootState } from '../../store'
import { get, intersection, isEmpty } from 'lodash'
import { selectAllTests } from '../Test/TestSlice'
import icfData from '../../data/icf.json'
import icdData from '../../data/icd.json'
import { selectAllDocumentations } from '../Documentation/DocumentationSlice'
import { Documentation } from '../Documentation/DocumentationType'
import { selectAllTestEvaluations } from '../TestEvaluation/TestEvaluationSlice'

const selectCase = (state: RootState, caseId: number) =>
    selectCaseById(state, caseId)
const selectTestSelections = (state: RootState) => state.testSelection.entities

export const makeFlagsByCase = () => {
    return createSelector([selectCase, selectAllFlags], (caseById, flags) => {
        return flags?.filter((flag) => caseById?.flags?.includes(flag.id))
    })
}

export const selectFlagsByCase = makeFlagsByCase()

export const makeDarkRedFlagsByCase = () => {
    return createSelector([selectFlagsByCase], (flags) => {
        return flags?.filter((flag) => flag.color === 'darkred')
    })
}

export const makeTestSelectionByCase = () => {
    return createSelector(
        [selectCase, selectTestSelections],
        (caseById, testSelections) => {
            if (!caseById) {
                return undefined
            }
            return testSelections[caseById.testSelection]
        }
    )
}

export const selectTestSelectionByCase = makeTestSelectionByCase()

export const makeTestsByTestSelection = () => {
    return createSelector(
        [selectTestSelectionByCase, selectAllTests],
        (testSelection, tests) => {
            return tests?.filter((test) =>
                testSelection?.tests.includes(test.id)
            )
        }
    )
}

export const selectTestsByTestSelectionByCase = makeTestsByTestSelection()

export const makeIcfsByCase = () => {
    return createSelector([selectCase], (caseById) => {
        if (!caseById || !caseById.caseIcfs || isEmpty(caseById.caseIcfs)) {
            return {
                chapter: [],
                block: [],
                fourdigit: [],
                fivedigit: [],
                sixdigit: [],
            }
        }
        const icfs = caseById.caseIcfs
        const chapter = icfData['chapter'].filter((icf) =>
            icfs.includes(icf.code)
        )
        const block = icfData['block'].filter((icf) => icfs.includes(icf.code))
        const fourdigit = icfData['fourdigit'].filter((icf) =>
            icfs.includes(icf.code)
        )
        const fivedigit = icfData['fivedigit'].filter((icf) =>
            icfs.includes(icf.code)
        )
        const sixdigit = icfData['sixdigit'].filter((icf) =>
            icfs.includes(icf.code)
        )

        return {
            chapter: chapter,
            block: block,
            fourdigit: fourdigit,
            fivedigit: fivedigit,
            sixdigit: sixdigit,
        }
    })
}

export const selectIcfsByCase = makeIcfsByCase()

export const makeIcdsByCase = () => {
    return createSelector([selectCase], (caseById) => {
        if (!caseById || !caseById.caseIcds || isEmpty(caseById.caseIcds)) {
            return {
                chapter: [],
                block: [],
                fourdigit: [],
                fivedigit: [],
                sixdigit: [],
            }
        }
        const icds = caseById.caseIcds
        const category = icdData['category'].filter((icd) =>
            icds.includes(icd.code)
        )

        return {
            category: category,
        }
    })
}

export const selectIcdsByCase = makeIcdsByCase()

export const makeDocumentationsByCase = () => {
    return createSelector(
        [selectCase, selectAllDocumentations],
        (caseById, documentations) => {
            if (!caseById) {
                return undefined
            }
            return documentations.filter((documentation) =>
                caseById.documentations.includes(documentation.id)
            )
        }
    )
}

export const makeTestEvaluationsByCase = () => {
    return createSelector(
        [selectCase, selectAllTestEvaluations],
        (caseById, testEvaluations) => {
            if (!caseById) {
                return undefined
            }
            return testEvaluations.filter((testEvaluation) =>
                caseById.testEvaluations.includes(testEvaluation.id)
            )
        }
    )
}

export const selectTestEvaluationsByCase = makeTestEvaluationsByCase()

const getDocmentationsByCase = makeDocumentationsByCase()
export const makeDocumentationsByCaseAndRejected = () => {
    return createSelector([getDocmentationsByCase], (documentations) => {
        if (!documentations) {
            return { rejected: [], notRejected: [], noTest: [] }
        }

        let out: {
            rejected: Documentation[]
            notRejected: Documentation[]
            noTest: Documentation[]
        } = { rejected: [], notRejected: [], noTest: [] }
        documentations.forEach((documentation) => {
            if (
                isEmpty(documentation?.rejectedTests) &&
                isEmpty(documentation?.tests)
            ) {
                out['noTest'].push(documentation)
            }
            let rejectedTests = (documentation?.rejectedTests ?? []).map(
                (value) => {
                    return parseInt(value)
                }
            )

            if (intersection(rejectedTests, documentation.tests).length > 0) {
                out['rejected'].push(documentation)
                out['notRejected'].push(documentation)
            } else if (!isEmpty(documentation.tests)) {
                out['notRejected'].push(documentation)
            }
        })
        return out
    })
}

export const makeTestsByIcf = () => {
    return createSelector([selectCase, selectAllTests], (caseById, tests) => {
        let caseIcfs = get(caseById, 'caseIcfs')
        if (isEmpty(caseIcfs) || isEmpty(tests)) {
            return []
        }
        return tests.filter((test) => {
            return !isEmpty(intersection(test.testIcfs, caseIcfs))
        })
    })
}

export const selectTestsByIcf = makeTestsByIcf()
