import { EntityId } from '@reduxjs/toolkit'
import { IonItem, IonLabel, IonList } from '@ionic/react'
import React, { useCallback } from 'react'
import { useAppDispatch } from '../../hooks'
import { deletePatientById } from './PatientSlice'
import { useHistory } from 'react-router'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'

interface PatientActionsProps {
    patientId: EntityId
}

const PatientActions = ({ patientId }: PatientActionsProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [deleteDialogOpen, setDeleteDialogOpen] =
        React.useState<boolean>(false)

    const handleDeletePatient = useCallback(async () => {
        await dispatch(deletePatientById({ id: patientId }))
        history.push('/patients')
    }, [dispatch, history, patientId])

    const handleDismiss = useCallback(() => {
        setDeleteDialogOpen(false)
    }, [])

    const openDeleteDialog = useCallback(() => {
        setDeleteDialogOpen(true)
    }, [setDeleteDialogOpen])

    return (
        <>
            <IonList inset>
                <IonItem
                    button
                    detail={false}
                    routerLink={'/patients/edit/' + patientId}
                >
                    <IonLabel color="primary">
                        Patientendaten bearbeiten
                    </IonLabel>
                </IonItem>
            </IonList>
            <IonList inset>
                <IonItem button detail={false} onClick={openDeleteDialog}>
                    <IonLabel color="danger">Patientendaten löschen</IonLabel>
                </IonItem>
            </IonList>
            <DeleteDialog
                header={'Patientendaten wirklich löschen?'}
                onDelete={handleDeletePatient}
                onDidDismiss={handleDismiss}
                isOpen={deleteDialogOpen}
            />
        </>
    )
}

export default PatientActions
