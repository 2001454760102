import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
} from '@ionic/react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ConnectedProps, connect } from 'react-redux'
import 'moment/locale/de'
import {
    deleteCaseById,
    fetchAllCases,
    selectAllCases,
} from '../Case/CaseSlice'
import { fetchPatientById, selectPatientById } from './PatientSlice'
import { RootState } from '../../store'
import { makeCasesByPatient } from './selectors'
import PatientActions from './PatientActions'
import PatientCaseOverview from './PatientCaseOverview'

type PatientDetailProps = {}

type PatientDetailState = {
    dataLoading: boolean
    deleteDialogOpen: boolean
    optionToDelete?: number
}

type RouterProps = RouteComponentProps<{ patientId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientDetailProps & RouterProps & PropsFromRedux

class PatientDetail extends React.Component<Props, PatientDetailState> {
    state: PatientDetailState = {
        dataLoading: true,
        deleteDialogOpen: false,
    }

    componentDidMount = async () => {
        const { fetchPatientById, fetchAllCases, patientId } = this.props

        this.setState({ dataLoading: true })

        try {
            await fetchPatientById({ id: patientId })
            await fetchAllCases({ patientId: patientId })
            this.setState({ dataLoading: false })
        } catch (err) {
            this.setState({ dataLoading: false })
        }
    }

    render() {
        const { dataLoading } = this.state
        const { patient } = this.props

        if (dataLoading || isEmpty(patient)) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            Patient: {patient.lastName}, {patient.firstName}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color="light">
                    <PatientActions patientId={patient.id} />
                    <PatientCaseOverview patientId={patient.id} />
                </IonContent>
            </IonPage>
        )
    }
}

const makeMapState = () => {
    const getCasesByPatient = makeCasesByPatient()

    return (state: RootState, ownProps: RouterProps) => {
        const patientId = parseInt(ownProps.match?.params?.patientId)
        const casesByPatient = getCasesByPatient(state, patientId)

        return {
            patientId: patientId,
            patient: selectPatientById(state, patientId),
            cases: selectAllCases(state),
            match: ownProps.match,
            casesByPatient: casesByPatient,
        }
    }
}

const mapDispatch = {
    fetchPatientById,
    deleteCaseById,
    fetchAllCases,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(PatientDetail))
