import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectFlagsByCase } from '../Case/selectors'
import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { Flag } from './FlagType'
import React from 'react'
import { addOutline, flag as flagIcon } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import FlagEditModal from './FlagEditModal'

interface FlagsOverviewProps {
    caseId: EntityId
}

function FlagsOverview({ caseId }: FlagsOverviewProps): JSX.Element {
    const flags = useAppSelector((state) => selectFlagsByCase(state, +caseId))
    const [modalOpen, setModalOpen] = React.useState(false)
    const [flagId, setFlagId] = React.useState<EntityId | null>(null)

    const flagMapping: Record<string, string> = {
        darkred: 'darkred',
        red: 'danger',
        yellow: 'warning',
        blue: 'primary',
        black: 'dark',
    }

    const renderFlag = (flag: Flag) => (
        <IonItem
            button
            onClick={() => {
                setFlagId(flag.id)
                setModalOpen(true)
            }}
            key={flag.id}
        >
            <IonIcon
                aria-hidden="true"
                slot="start"
                icon={flagIcon}
                color={flagMapping[flag.color]}
            />
            <IonLabel>
                <IonText>{flag.shortDescription}</IonText>
            </IonLabel>
        </IonItem>
    )

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Flaggen</IonLabel>
                    <IonButton
                        onClick={() => {
                            setModalOpen(true)
                            setFlagId(null)
                        }}
                        className="ion-hide-sm-down"
                    >
                        Flagge hinzufügen
                    </IonButton>
                    <IonButton
                        onClick={() => {
                            setModalOpen(true)
                            setFlagId(null)
                        }}
                        className="ion-hide-sm-up"
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {!isEmpty(flags) ? (
                    flags.map(renderFlag)
                ) : (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>Keine Flaggen angelegt</IonText>
                    </div>
                )}
            </IonList>
            <FlagEditModal
                flagId={flagId}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            />
        </>
    )
}

export default FlagsOverview
