import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { selectPatientById } from './PatientSlice'
import { selectAllCases } from '../Case/CaseSlice'
import { isEmpty } from 'lodash'

const selectPatient = (state: RootState, patientId: number) =>
    selectPatientById(state, patientId)

export const makeCasesByPatient = () => {
    return createSelector([selectPatient, selectAllCases], (patient, cases) => {
        if (!patient || isEmpty(cases)) {
            return undefined
        }

        return cases.filter((selectedCase) =>
            patient.cases?.includes(selectedCase.id)
        )
    })
}
export const getCasesByPatient = makeCasesByPatient()
