import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { selectAllAims } from './AimSlice'
import { selectCaseById } from '../Case/CaseSlice'

const selectAims = (state: RootState) => selectAllAims(state)

// https://stackoverflow.com/questions/40291084/use-reselect-selector-with-parameters
export const makeSelectAimsByCase = () => {
    return createSelector(
        [
            selectAims,
            (state: RootState, caseId: number) => selectCaseById(state, caseId),
        ],
        (aims, caseById) => {
            return aims.filter((aim) => caseById?.aims?.includes(aim.id))
        }
    )
}

export const selectAimsByCase = makeSelectAimsByCase()
