import { useCallback, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { Flag } from './FlagType'
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonNote,
    IonPage,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React from 'react'
import { EntityId } from '@reduxjs/toolkit'
import {
    createFlag,
    deleteFlagById,
    selectFlagById,
    updateFlag,
} from './FlagSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useParams } from 'react-router-dom'
import { createCase, fetchCaseById } from '../Case/CaseSlice'
import { useHistory } from 'react-router'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'

interface FlagEditModalProps {
    open: boolean
    onClose: () => void
    flagId: EntityId | null
}

function FlagEditModal({
    open,
    onClose,
    flagId,
}: FlagEditModalProps): JSX.Element {
    const dispatch = useAppDispatch()
    const { patientId, caseId } = useParams<{
        patientId: string
        caseId: string
    }>()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const history = useHistory()
    const flag = useAppSelector((state) =>
        flagId ? selectFlagById(state, +flagId) : null
    )

    const handleDeleteDialogOpen = useCallback(() => {
        setDeleteDialogOpen(true)
    }, [])

    const handleDismissDeleteDialog = useCallback(() => {
        setDeleteDialogOpen(false)
    }, [])

    const handleDelete = useCallback(async () => {
        await dispatch(deleteFlagById({ id: flagId!, patientId, caseId }))
        setDeleteDialogOpen(false)
        onClose()
    }, [caseId, dispatch, flagId, onClose, patientId])

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            if (!flagId && caseId !== 'new') {
                //Case existiert aber Flagge nicht -> Flagge erstellen
                await dispatch(
                    createFlag({
                        data: values,
                        caseId: caseId,
                        patientId,
                    })
                )
                await dispatch(fetchCaseById({ id: caseId, patientId }))
                onClose()
            } else if (!flagId && caseId === 'new') {
                // case existiert nicht -> case anlegen, flagge anlegen, redirect
                const response = await dispatch(
                    createCase({
                        data: values,
                        patientId,
                    })
                ).unwrap()
                const newCaseId = Object.keys(response.entities.case)[0]
                await dispatch(
                    createFlag({
                        data: values,
                        caseId: newCaseId,
                        patientId,
                    })
                )
                history.replace(`/patients/${patientId}/cases/${newCaseId}`)
                onClose()
            } else {
                // case existiert, flagge existiert -> Flagge updaten
                await dispatch(
                    updateFlag({
                        id: flagId!,
                        caseId: caseId,
                        patientId,
                        data: values,
                    })
                )
                await dispatch(fetchCaseById({ id: caseId, patientId }))
                onClose()
            }
        },
        [caseId, dispatch, flagId, history, onClose, patientId]
    )

    return (
        <>
            <IonModal isOpen={open} onDidDismiss={onClose}>
                <Formik
                    initialValues={flag ?? ({} as Flag)}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <IonPage>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonButtons slot="start">
                                            <IonButton
                                                color="medium"
                                                onClick={onClose}
                                            >
                                                Abbrechen
                                            </IonButton>
                                        </IonButtons>
                                        <IonTitle>
                                            {flagId
                                                ? `Flagge bearbeiten`
                                                : 'Neue Flagge'}
                                        </IonTitle>
                                        <IonButtons slot="end">
                                            {props.isSubmitting ? (
                                                <IonSpinner />
                                            ) : (
                                                <IonButton
                                                    onClick={props.submitForm}
                                                >
                                                    Speichern
                                                </IonButton>
                                            )}
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent
                                    className="ion-padding"
                                    color="light"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '10px',
                                            paddingBottom: '16px',
                                        }}
                                    >
                                        <IonButton
                                            fill={
                                                props.values?.color !==
                                                'darkred'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="darkred"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'darkred'
                                                )
                                            }}
                                        >
                                            Dunkelrot
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'red'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="danger"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'red'
                                                )
                                            }}
                                        >
                                            Rot
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'yellow'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="warning"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'yellow'
                                                )
                                            }}
                                        >
                                            Gelb
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'blue'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'blue'
                                                )
                                            }}
                                        >
                                            Blau
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'black'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="dark"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'black'
                                                )
                                            }}
                                        >
                                            Schwarz
                                        </IonButton>
                                    </div>
                                    <IonNote
                                        color="medium"
                                        class="ion-margin-horizontal"
                                    >
                                        Wählen Sie hier die Farbe der Flagge.
                                    </IonNote>
                                    <IonList inset>
                                        <IonItem>
                                            <IonInput
                                                name="shortDescription"
                                                label="Kurzbeschreibung"
                                                value={
                                                    props.values
                                                        ?.shortDescription ?? ''
                                                }
                                                onIonChange={props.handleChange}
                                            ></IonInput>
                                        </IonItem>
                                        <IonItem>
                                            <IonTextarea
                                                name="description"
                                                label="Beschreibung"
                                                autoGrow={true}
                                                onIonChange={props.handleChange}
                                                value={
                                                    props.values?.description ??
                                                    ''
                                                }
                                            ></IonTextarea>
                                        </IonItem>
                                    </IonList>
                                    {flagId && (
                                        <IonList inset>
                                            <IonItem
                                                button
                                                detail={false}
                                                onClick={handleDeleteDialogOpen}
                                            >
                                                <IonLabel color="danger">
                                                    Flagge löschen
                                                </IonLabel>
                                            </IonItem>
                                        </IonList>
                                    )}
                                </IonContent>
                            </IonPage>
                        </Form>
                    )}
                </Formik>
            </IonModal>
            <DeleteDialog
                header="Möchten Sie die Flagge wirklich löschen?"
                onDelete={handleDelete}
                onDidDismiss={handleDismissDeleteDialog}
                isOpen={deleteDialogOpen}
            />
        </>
    )
}

export default FlagEditModal
