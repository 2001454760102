import React, { JSX, memo, useCallback } from 'react'
import { IcfIcdEntry } from '../../utils/IcfIcdType'
import {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
} from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import IcfIcdInfoModal from './IcfIcdInfoModal'
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core'

interface IcdEntryProps {
    entry: IcfIcdEntry
    selected: boolean
    onCheckboxSelection: (checked: boolean, icd: string) => void
}

const IcfIcdSearchEntry = ({
    entry,
    selected,
    onCheckboxSelection,
}: IcdEntryProps): JSX.Element => {
    const [open, setOpen] = React.useState(false)

    const handleCheckboxSelection = useCallback(
        (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) => {
            onCheckboxSelection(e.detail.checked, entry.code)
        },
        [entry.code, onCheckboxSelection]
    )

    return (
        <>
            <IonItem key={entry.code} lines="inset">
                <IonLabel>
                    <IonText>{`${entry.code} - ${entry.label}`}</IonText>
                </IonLabel>
                <IonCheckbox
                    slot="start"
                    aria-label="Select ICD"
                    checked={selected}
                    onIonChange={handleCheckboxSelection}
                ></IonCheckbox>
                <IonButton
                    id={'info-' + entry.code}
                    onClick={() => {
                        setOpen(true)
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
            </IonItem>
            {open && (
                <IcfIcdInfoModal
                    icd={entry}
                    onDismiss={() => setOpen(false)}
                    open={open}
                />
            )}
        </>
    )
}

export default memo(IcfIcdSearchEntry)
