import React, { JSX, useCallback } from 'react'
import {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
} from '@ionic/react'
import { Test } from '../Test/TestType'
import { informationCircleOutline } from 'ionicons/icons'
import TestDetailModal from './TestDetailModal'
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core'

interface TestEntryProps {
    entry: Test
    selected: boolean
    onCheckboxSelection: (testId: number) => void
}

const TestEntry = ({
    entry,
    selected,
    onCheckboxSelection,
}: TestEntryProps): JSX.Element => {
    const [detailsOpen, setDetailsOpen] = React.useState(false)
    const [selectedTest, setSelectedTest] = React.useState<Test | null>(null)

    const onInfo = (test: Test) => {
        setSelectedTest(test)
        setDetailsOpen(true)
    }

    const handleDismiss = () => {
        setDetailsOpen(false)
    }

    const handleCheckboxSelection = useCallback(
        (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) => {
            onCheckboxSelection(entry.id)
        },
        [entry.id, onCheckboxSelection]
    )

    return (
        <>
            <IonItem>
                <IonLabel>{entry.name}</IonLabel>
                <IonCheckbox
                    slot="start"
                    aria-label="Select ICD"
                    checked={selected}
                    onIonChange={handleCheckboxSelection}
                ></IonCheckbox>
                <IonButton
                    id={'info-' + entry.id}
                    onClick={() => onInfo(entry)}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
            </IonItem>
            {selectedTest && detailsOpen && (
                <TestDetailModal
                    open={detailsOpen}
                    onDismiss={handleDismiss}
                    test={selectedTest}
                />
            )}
        </>
    )
}

export default TestEntry
