import { IonChip, IonItem, IonLabel, IonText } from '@ionic/react'
import moment from 'moment'
import { FC } from 'react'
import { useAppSelector } from '../../hooks'
import { selectTestEvaluationById } from './TestEvaluationSlice'
import { isEmpty } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router'

type TestEvaluationProps = {
    testEvaluationId: number
}

const TestEvaluationItem: FC<TestEvaluationProps> = ({ testEvaluationId }) => {
    const testEvaluation = useAppSelector((state) =>
        selectTestEvaluationById(state, testEvaluationId)
    )
    const history = useHistory()
    const { url } = useRouteMatch()

    if (!testEvaluation) {
        return <></>
    }

    const rejectedTestsCount = testEvaluation.rejectedTests?.length ?? 0

    const testLabel = !isEmpty(testEvaluation.tests)
        ? `${
              testEvaluation.tests!.length === 1
                  ? '1 Test'
                  : testEvaluation.tests!.length + ' Tests'
          } ${
              rejectedTestsCount > 0
                  ? ' (' + rejectedTestsCount + ' verworfen)'
                  : ''
          }`
        : null

    return (
        <IonItem
            button
            onClick={(e) => {
                e.preventDefault()
                history.push(`${url}/testevaluations/${testEvaluation.id}`)
            }}
        >
            <IonLabel>
                <div>
                    <IonText>
                        {moment(testEvaluation.date).format('llll')}
                    </IonText>
                </div>
                {(testLabel || !isEmpty(testEvaluation.icfs)) && (
                    <div style={{ paddingTop: '8px' }}>
                        <IonText>
                            {testLabel && (
                                <IonChip color="secondary">{testLabel}</IonChip>
                            )}
                            {!isEmpty(testEvaluation.icfs) &&
                                testEvaluation.icfs.map((icf) => {
                                    return (
                                        <IonChip key={icf} color="tertiary">
                                            {icf}
                                        </IonChip>
                                    )
                                })}
                        </IonText>
                    </div>
                )}
            </IonLabel>
        </IonItem>
    )
}

export default TestEvaluationItem
