import React, { JSX } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useAppSelector } from '../../hooks'
import { selectCaseById } from '../Case/CaseSlice'
import { useRouteMatch } from 'react-router-dom'
import { selectInspectionById } from './InspectionSlice'

interface InspectionOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const InspectionOverview = ({
    caseId,
    disabled,
}: InspectionOverviewProps): JSX.Element => {
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const inspection = useAppSelector((state) =>
        caseById?.inspection
            ? selectInspectionById(state, +caseById.inspection)
            : null
    )
    const match = useRouteMatch()

    return (
        <IonList inset>
            <IonListHeader>
                <IonLabel>Inspektion</IonLabel>
                {!inspection && (
                    <>
                        <IonButton
                            routerLink={`${match.url}/inspection`}
                            className="ion-hide-sm-down"
                            disabled={disabled}
                        >
                            Inspektion anlegen
                        </IonButton>
                        <IonButton
                            routerLink={`${match.url}/inspection`}
                            className="ion-hide-sm-up"
                            disabled={disabled}
                        >
                            <IonIcon slot="icon-only" icon={addOutline} />
                        </IonButton>
                    </>
                )}
            </IonListHeader>
            {inspection ? (
                <>
                    <IonItem>
                        <IonLabel>
                            <IonText>{inspection.inspection}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        routerLink={`${match.url}/inspection`}
                    >
                        <IonLabel color="primary">
                            Inspektion bearbeiten
                        </IonLabel>
                    </IonItem>
                    {/*<IonItem button detail={false}>
                            <IonLabel color="danger">Inspektion löschen</IonLabel>
                        </IonItem>*/}
                </>
            ) : (
                <div className="ion-text-center ion-padding ion-margin">
                    <IonText>Keine Inspektion vorhanden</IonText>
                </div>
            )}
        </IonList>
    )
}

export default InspectionOverview
