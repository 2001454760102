import React, { JSX, useCallback } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCaseById, updateCase } from '../Case/CaseSlice'
import { EntityId } from '@reduxjs/toolkit'
import { useParams } from 'react-router-dom'

interface PersonalFactorsModalProps {
    open: boolean
    onDismiss: () => void
    caseId: EntityId
}

const PersonalFactorsModal = ({
    open,
    onDismiss,
    caseId,
}: PersonalFactorsModalProps): JSX.Element => {
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const dispatch = useAppDispatch()
    const { patientId } = useParams<{ patientId: string }>()

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            await dispatch(updateCase({ data: values, id: caseId, patientId }))
            onDismiss()
        },
        [caseId, dispatch, onDismiss, patientId]
    )

    return (
        <IonModal isOpen={open} onDidDismiss={onDismiss}>
            <Formik initialValues={{ ...caseById }} onSubmit={handleSubmit}>
                {({ submitForm, handleChange, values, isSubmitting }) => (
                    <Form>
                        <IonPage>
                            <IonHeader>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={onDismiss}
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>
                                        Personenbezogene Faktoren
                                    </IonTitle>
                                    <IonButtons slot="end">
                                        {isSubmitting ? (
                                            <IonSpinner />
                                        ) : (
                                            <IonButton onClick={submitForm}>
                                                Speichern
                                            </IonButton>
                                        )}
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent color="light">
                                <IonList inset>
                                    <IonListHeader>
                                        <IonLabel>(i)</IonLabel>
                                    </IonListHeader>
                                    <IonItem>
                                        <IonTextarea
                                            name="personalFactors"
                                            label="Personenbezogene Faktoren"
                                            labelPlacement="floating"
                                            autoGrow={true}
                                            onIonChange={handleChange}
                                            value={values.personalFactors ?? ''}
                                        ></IonTextarea>
                                    </IonItem>
                                </IonList>
                            </IonContent>
                        </IonPage>
                    </Form>
                )}
            </Formik>
        </IonModal>
    )
}

export default PersonalFactorsModal
