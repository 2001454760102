import React, { JSX, useCallback } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIcfsByCase } from '../Case/selectors'
import { IcfIcdEntry } from '../../utils/IcfIcdType'
import { isEmpty, without } from 'lodash'
import IcfIcdItem from '../../components/IcfIcd/IcfIcdItem'
import IcfIcdInfoModal from '../../components/IcfIcd/IcfIcdInfoModal'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import { selectCaseById, updateCase } from '../Case/CaseSlice'
import PersonalFactorsModal from './PersonalFactorsModal'

interface IcfOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const IcfOverview = ({ caseId, disabled }: IcfOverviewProps): JSX.Element => {
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const { url } = useRouteMatch()
    const icfs = useAppSelector((state) => selectIcfsByCase(state, +caseId))
    const [infoModalOpen, setInfoModalOpen] = React.useState(false)
    const [personalFactorsOpen, setPersonalFactorsOpen] = React.useState(false)
    const [selectedIcf, setSelectedIcf] = React.useState<IcfIcdEntry | null>(
        null
    )
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const { patientId } = useParams<{ patientId: string }>()

    const handleInfoOpen = useCallback((icf: IcfIcdEntry) => {
        setSelectedIcf(icf)
        setInfoModalOpen(true)
    }, [])

    const handleDeleteDialogOpen = useCallback((icf: IcfIcdEntry) => {
        setSelectedIcf(icf)
        setDeleteDialogOpen(true)
    }, [])

    const handleDelete = async () => {
        const values = {
            ...caseById,
            caseIcfs: without(caseById?.caseIcfs, selectedIcf!.code),
        }
        await dispatch(updateCase({ data: values, id: caseId, patientId }))

        setSelectedIcf(null)
        setDeleteDialogOpen(false)
    }

    const handlePersonalFactorsOpen = useCallback(() => {
        setPersonalFactorsOpen(true)
    }, [])

    const handlePersonalFactorsClose = useCallback(() => {
        setPersonalFactorsOpen(false)
    }, [])

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>ICF</IonLabel>
                    <IonButton
                        routerLink={`${url}/icf`}
                        className="ion-hide-sm-down"
                        disabled={disabled}
                    >
                        ICF hinzufügen
                    </IonButton>
                    <IonButton
                        routerLink={`${url}/icf`}
                        className="ion-hide-sm-up"
                        disabled={disabled}
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {!isEmpty(icfs.chapter) && (
                    <>
                        <IonListHeader>Kapitel</IonListHeader>
                        {icfs.chapter.map((icf) => (
                            <IcfIcdItem
                                entry={icf}
                                key={icf.code}
                                onInfo={handleInfoOpen}
                                onDelete={handleDeleteDialogOpen}
                            />
                        ))}
                    </>
                )}
                {!isEmpty(icfs.block) && (
                    <>
                        <IonListHeader>Gruppe</IonListHeader>
                        {icfs.block.map((icf, index) => (
                            <IcfIcdItem
                                entry={icf}
                                key={icf.code}
                                onInfo={handleInfoOpen}
                                onDelete={handleDeleteDialogOpen}
                            />
                        ))}
                    </>
                )}
                {!isEmpty(icfs.fourdigit) && (
                    <>
                        <IonListHeader>Kategorie</IonListHeader>
                        {icfs.fourdigit.map((icf, index) => (
                            <IcfIcdItem
                                entry={icf}
                                key={icf.code}
                                onInfo={handleInfoOpen}
                                onDelete={handleDeleteDialogOpen}
                            />
                        ))}
                    </>
                )}
                {!isEmpty(icfs.fivedigit) && (
                    <>
                        <IonListHeader>Subkategorie 1</IonListHeader>
                        {icfs.fivedigit.map((icf, index) => (
                            <IcfIcdItem
                                entry={icf}
                                key={icf.code}
                                onInfo={handleInfoOpen}
                                onDelete={handleDeleteDialogOpen}
                            />
                        ))}
                    </>
                )}
                {!isEmpty(icfs.sixdigit) && (
                    <>
                        <IonListHeader>Subkategorie 2</IonListHeader>
                        {icfs.sixdigit.map((icf, index) => (
                            <IcfIcdItem
                                entry={icf}
                                key={icf.code}
                                onInfo={handleInfoOpen}
                                onDelete={handleDeleteDialogOpen}
                            />
                        ))}
                    </>
                )}
                <IonListHeader>Personenbezogene Faktoren (i)</IonListHeader>
                {caseById?.personalFactors && (
                    <IonItem>
                        <IonLabel>
                            <IonText>{caseById.personalFactors}</IonText>
                        </IonLabel>
                    </IonItem>
                )}
                <IonItem
                    button
                    detail={false}
                    onClick={handlePersonalFactorsOpen}
                    disabled={disabled}
                >
                    <IonLabel color="primary">
                        Personenbezogene Faktoren{' '}
                        {caseById?.personalFactors
                            ? 'bearbeiten'
                            : 'hinzufügen'}
                    </IonLabel>
                </IonItem>
            </IonList>
            <PersonalFactorsModal
                open={personalFactorsOpen}
                onDismiss={handlePersonalFactorsClose}
                caseId={caseId}
            />
            <IcfIcdInfoModal
                open={infoModalOpen}
                icd={selectedIcf}
                onDismiss={() => {
                    setInfoModalOpen(false)
                }}
            />
            <DeleteDialog
                header={`${selectedIcf?.code} wirklich löschen?`}
                onDelete={handleDelete}
                onDidDismiss={() => {
                    setDeleteDialogOpen(false)
                }}
                isOpen={deleteDialogOpen}
            />
        </>
    )
}

export default IcfOverview
