import React, { useState, useEffect } from 'react'
import {
    IonButton,
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonText,
    IonIcon,
} from '@ionic/react'
import { menuController } from '@ionic/core'
import { isEmpty } from 'lodash'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { User } from './UserType'
import { fetchAllUsers, selectAllUsers } from './UserSlice'
import ACL from '../../components/ACL'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { addOutline } from 'ionicons/icons'

const UserList = (): JSX.Element => {
    const users = useAppSelector(selectAllUsers)
    const dispatch = useAppDispatch()
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        setDataLoading(true)

        dispatch(fetchAllUsers())
            .then(() => setDataLoading(false))
            .catch(() => setDataLoading(false))
    }, [dispatch])

    const renderUsers = () => {
        if (isEmpty(users)) {
            return null
        }

        return (
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Benutzerübersicht</IonLabel>
                    <ACL allowedRoles={[RoleEnum.ROLE_ADMIN]}>
                        <IonButton
                            routerLink="/users/register"
                            className="ion-hide-sm-down"
                        >
                            Benutzer anlegen
                        </IonButton>
                        <IonButton
                            routerLink="/users/register"
                            className="ion-hide-sm-up"
                        >
                            <IonIcon slot="icon-only" icon={addOutline} />
                        </IonButton>
                    </ACL>
                </IonListHeader>
                {users.map(renderUser)}
            </IonList>
        )
    }

    const renderUser = (user: User, index: number) => (
        <IonItem routerLink={`/users/${user.id}`} key={index}>
            <IonLabel>
                <IonText>{user.firstName + ' ' + user.lastName}</IonText>
                <p>
                    <IonText>{user.email}</IonText>
                </p>
            </IonLabel>
        </IonItem>
    )

    return (
        <IonPage>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton
                        autoHide={true}
                        onClick={() => {
                            menuController.open()
                        }}
                    ></IonMenuButton>
                </IonButtons>
                <IonTitle>Benutzerübersicht</IonTitle>
            </IonToolbar>
            <IonContent color="light">
                {dataLoading ? null : renderUsers()}
            </IonContent>
        </IonPage>
    )
}

export default UserList
