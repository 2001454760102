import React, { JSX, useCallback, useState } from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { EntityId } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    selectTestsByTestSelectionByCase,
    selectTestSelectionByCase,
} from '../Case/selectors'
import { isEmpty, without } from 'lodash'
import { useParams, useRouteMatch } from 'react-router-dom'
import TestSelectionOverviewEntry from './TestSelectionOverviewEntry'
import { Test } from '../Test/TestType'
import {
    deleteTestSelectionById,
    updateTestSelection,
} from './TestSelectionSlice'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import TestDetailModal from './TestDetailModal'

interface TestSelectionOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const TestSelectionOverview = ({
    caseId,
    disabled,
}: TestSelectionOverviewProps): JSX.Element => {
    const tests = useAppSelector((state) =>
        selectTestsByTestSelectionByCase(state, +caseId)
    )
    const match = useRouteMatch()
    const [selectedTest, setSelectedTest] = useState<Test | null>(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
    const [infoModalOpen, setInfoModalOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const testSelectionByCase = useAppSelector((state) =>
        selectTestSelectionByCase(state, +caseId)
    )
    const { patientId } = useParams<{ patientId: string }>()

    const handleInfoOpen = useCallback((test: Test) => {
        setSelectedTest(test)
        setInfoModalOpen(true)
    }, [])

    const handleInfoDismiss = useCallback(() => {
        setSelectedTest(null)
        setInfoModalOpen(false)
    }, [])

    const handleDeleteDialogOpen = useCallback((test: Test) => {
        setSelectedTest(test)
        setDeleteDialogOpen(true)
    }, [])

    const handleUpdate = async () => {
        if (!testSelectionByCase) {
            return
        }

        const values = {
            ...testSelectionByCase,
            tests: without(testSelectionByCase.tests, selectedTest!.id),
        }

        if (!isEmpty(values.tests)) {
            await dispatch(
                updateTestSelection({
                    data: values,
                    id: testSelectionByCase.id,
                    patientId,
                    caseId,
                })
            )
        } else {
            await dispatch(
                deleteTestSelectionById({
                    id: testSelectionByCase.id,
                    patientId,
                    caseId,
                })
            )
        }

        setSelectedTest(null)
        setDeleteDialogOpen(false)
    }

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Testauswahl</IonLabel>
                    <>
                        <IonButton
                            routerLink={`${match.url}/testselection`}
                            className="ion-hide-sm-down"
                            disabled={disabled}
                        >
                            Test hinzufügen
                        </IonButton>
                        <IonButton
                            routerLink={`${match.url}/testselection`}
                            className="ion-hide-sm-up"
                            disabled={disabled}
                        >
                            <IonIcon slot="icon-only" icon={addOutline} />
                        </IonButton>
                    </>
                </IonListHeader>
                {isEmpty(tests) ? (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>Keine Tests ausgewählt</IonText>
                    </div>
                ) : (
                    tests.map((test) => (
                        <TestSelectionOverviewEntry
                            key={test.id}
                            testId={test.id}
                            onInfo={handleInfoOpen}
                            onDelete={handleDeleteDialogOpen}
                        />
                    ))
                )}
            </IonList>
            <DeleteDialog
                header={`Möchten Sie den Test wirklich löschen?`}
                onDelete={handleUpdate}
                onDidDismiss={() => {
                    setDeleteDialogOpen(false)
                }}
                isOpen={deleteDialogOpen}
            />
            {selectedTest && infoModalOpen && (
                <TestDetailModal
                    open={infoModalOpen}
                    onDismiss={handleInfoDismiss}
                    test={selectedTest}
                />
            )}
        </>
    )
}

export default TestSelectionOverview
