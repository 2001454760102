import React, { JSX, useMemo } from 'react'
import { exactSearch } from '../../utils/IcfIcdHandler'
import { IcfIcdModeEnum } from '../../utils/Enum/IcfIcdEnum'
import { IonCheckbox, IonItem, IonLabel, IonText } from '@ionic/react'
import { useFormikContext } from 'formik'
import { TestEvaluation } from './TestEvaluationType'
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core'

interface IcfItemProps {
    icf: string
}

const IcfItem = ({ icf }: IcfItemProps): JSX.Element => {
    const icfEntry = useMemo(() => {
        return exactSearch(icf, IcfIcdModeEnum.MODE_ICF)
    }, [icf])
    const { values, setFieldValue } = useFormikContext<TestEvaluation>()

    const handleCheckboxChange = (
        e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>
        //e: MouseEventHandler<HTMLIonCheckboxElement>
    ) => {
        if (!e.detail.checked && values.icfs.includes(icf)) {
            setFieldValue(
                'icfs',
                values.icfs.filter((x: string) => x !== icf)
            )
        } else {
            const value = values?.icfs ? [...values.icfs, icf] : [icf]
            setFieldValue('icfs', value)
        }
    }

    return (
        <IonItem button={false}>
            <IonLabel>
                <IonText>
                    {icfEntry?.code} - {icfEntry?.label}
                </IonText>
            </IonLabel>
            <IonCheckbox
                slot="start"
                aria-label="Patientenziel"
                checked={values?.icfs.includes(icf)}
                onIonChange={handleCheckboxChange}
            ></IonCheckbox>
        </IonItem>
    )
}

export default IcfItem
